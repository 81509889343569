function Report({apiResponse}) {
    return (
        <>
            <div>
                <h1 className='font-roboto text-[26px] font-normal leading-[32.5px] text-left text-white mb-5'>
                    Your Sleep Apnea Risk is:
                    <br />
                    <p className="font-bold mt-5">Moderate</p>
                </h1>

                <p className="font-roboto text-[22px] font-extrabold leading-[27.5px] text-left text-[#1B7CED]">
                    Key Factors identified
                </p>
                <br />
                <p className="font-roboto text-[18px] font-normal leading-[22.5px] text-left text-[white]">{apiResponse}</p>
                {/* <div className="font-roboto text-[18px] font-normal leading-[22.5px] text-left text-[white]">
                    <p className="mb-2">
                        <span className="font-bold">Facial Symmetry Analysis: </span> Indicates potential airway obstructions.
                    </p>
                    <p className="mb-2">
                        <span className="font-bold">Mouth Structure Evaluation: </span>  Suggests compromised airway space.
                    </p>
                    <p className="mb-2">
                        <span className="font-bold">Jaw Alignment Check: </span> Related to breathing abnormalities during sleep.
                    </p>
                    <p className="mb-2">
                        <span className="font-bold">Neck Circumference Measurement: </span>
                        Associates with increased apnea risk.
                    </p>
                </div> */}
                <br />
                <p className="font-roboto text-[16.5px] font-normal leading-[20.63px] text-center text-white">These assessments are based on academic research and refined by AI algorithms.</p>
            </div>
        </>
    )
}
export default Report;


