function ProgressBar({ showProgressBar, percentage }) {
    if (showProgressBar) {
        return (
            <div className="flex items-center w-full">
                <div className="w-[125px] bg-[#1E1E1E] rounded-[4px] h-[6px] mr-3">
                    <div className="bg-[#4CD71C] h-[6px] rounded-[4px]" style={{ width: `${percentage}%` }}></div>
                </div>
                <span className="font-inter text-base font-normal leading-5 text-center text-[#4CD71C]">{percentage}%</span>
            </div>
        )
    }
    return null;
}

export default ProgressBar;