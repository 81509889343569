import Landing from '../Landing';
import Feature from '../Feature';
import Agreement from '../Agreement';
import ScanInstruction from '../ScanInstruction';
import Camera from '../Camera';
import Scan from '../Scan';
import Question from '../Question';
import Email from '../Email';
import Report from '../Report';

export const steps = [
    {
        'name': 'Landing',
        'showLogo': true,
        'showButton': true,
        'buttonText': 'Start Now',
        'buttonType': 'button',
        'component': (props) => <Landing {...props} />
    },
    {
        'name': 'Feature',
        'showLogo': true,
        'showButton': true,
        'buttonText': 'Continue',
        'buttonType': 'button',
        'component': (props) => <Feature {...props} />
    },
    {
        'name': 'Agreement',
        'showLogo': true,
        'showButton': true,
        'buttonText': 'Agree',
        'buttonType': 'button',
        'component': (props) => <Agreement {...props} />
    },
    {
        'name': 'Front Instruction',
        'showLogo': false,
        'showHeader': true,
        'showButton': true,
        'buttonText': 'Initiate Scan',
        'buttonType': 'button',
        'component': (props) => <ScanInstruction {...props} details={frontFaceInstructions} />
    },
    {
        'name': 'Front Camera',
        'showHeader': true,
        'showButton': false,
        'buttonText': 'Continue',
        'buttonType': 'button',
        'component': (props) => <Camera {...props} details={frontCameraDetails} />
    },
    {
        'name': 'Side Instruction',
        'showLogo': false,
        'showHeader': true,
        'showButton': true,
        'buttonText': 'Begin Side-Scan',
        'buttonType': 'button',
        'component': (props) => <ScanInstruction {...props} details={sideFaceInstructions} />
    },
    {
        'name': 'Side Camera',
        'showHeader': true,
        'showButton': false,
        'buttonText': 'Continue',
        'buttonType': 'button',
        'component': (props) => <Camera {...props} details={sideCameraDetails} />
    },
    {
        'name': 'Scan Complete',
        'showLogo': false,
        'showButton': true,
        'buttonText': 'Done',
        'buttonType': 'button',
        'component': (props) => <Scan {...props} />
    },
    {
        'name': 'QuestionOne',
        'showHeader': true,
        'component': (props) => <Question {...props} details={QuestionOneDetails} />,
        'showButton': true,
        'buttonType': 'button',
        'buttonText': 'Continue',
    },
    {
        'name': 'QuestionTwo',
        'showHeader': true,
        'component': (props) => <Question {...props} details={QuestionTwoDetails} />,
        'showButton': true,
        'buttonType': 'button',
        'buttonText': 'Continue',
    },
    {
        'name': 'QuestionThree',
        'showHeader': true,
        'component': (props) => <Question {...props} details={QuestionThreeDetails} />,
        'showButton': true,
        'buttonType': 'button',
        'buttonText': 'Get My Result',
    },
    {
        'name': 'Email',
        'showHeader': false,
        'component': (props) => <Email {...props} />,
        'showButton': true,
        'buttonType': 'submit',
        'buttonText': 'Get My Result',
    },
    {
        'name': 'Scan Complete',
        'showLogo': false,
        'showButton': false,
        // 'buttonText': 'Dosdfsdne',
        // 'buttonType': 'button',
        'component': (props) => <Scan {...props} />
    },
    // {
    //     'name': 'Report',
    //     'showHeader': false,
    //     'showLogo': true,
    //     'component': (props) => <Report {...props} />,
    //     'showButton': true,
    //     'buttonType': 'button',
    //     'buttonText': 'Order In-Home Sleep Study',
    //     'isLink': true,
    //     'linkUrl': 'https://royahealth.app/'
    // },

];

export const QuestionOneDetails = {
    'name': 'prev_sleep_study',
    'title': 'Previous Sleep Study',
    'details': 'Have you done a sleep study before?',
    'percentage': 60,
    'options': [
        {
            'value': 'Yes',
            'displayText': 'Yes',
        },
        {
            'value': 'No',
            'displayText': 'No',
        }
    ]
}
export const QuestionTwoDetails = {
    'name': 'prev_diag_result',
    'title': 'Previous Diagnosis Result',
    "details": 'What was the severity of your sleep apnea according to the test results?',
    'percentage': 70,
    'options': [
        {
            'value': 'None',
            'displayText': 'None',
        },
        {
            'value': 'Mild',
            'displayText': 'Mild',
        },
        {
            'value': 'Moderate',
            'displayText': 'Moderate',
        },
        {
            'value': 'Severe',
            'displayText': 'Severe',
        },
        {
            'value': 'Not Sure',
            'displayText': 'Not Sure',
        }
    ]
}
export const QuestionThreeDetails = {
    'name': 'osa_reason',
    'title': 'Reason for OSA Interest',
    "details": "What's the reason you're interested in learning about your chances of having sleep apnea?",
    'percentage': 80,
    'options': [
        {
            'value': 'Snoring',
            'displayText': 'Snoring',
        },
        {
            'value': 'Signs of stopped breathing',
            'displayText': 'Signs of stopped breathing',
        },
        {
            'value': 'Sleepiness during the day',
            'displayText': 'Sleepiness during the day',
        },
        {
            'value': 'Health consciousness',
            'displayText': 'Health consciousness',
        },
        {
            'value': 'Other',
            'displayText': 'Other',
        }
    ]
}

export const frontFaceInstructions = {
    'title': 'Take a Front-Facing Photo',
    'list': [
        'Positioning: Keep your head straight with the camera directly in front of you.',
        'Visibility: Ensure your entire face and neck are clearly visible in the frame.',
        'Hair: Pull back any hair to prevent obstruction of your face and neck.'
    ],
    'percentage': 20,
}

export const sideFaceInstructions = {
    'title': 'Take a Side-View Photo',
    'list': [
        'Positioning: Turn your head to the side so that your profile is facing the camera.',
        'Visibility: Ensure your entire face and neck are clearly visible in the frame.',
        'Hair: Pull back any hair to prevent obstruction of your face.'
    ],
    'percentage': 40,
}

export const frontCameraDetails = {
    'title': 'Position your face and neck within the frame.',
    'subTitle': '(facing the camera)',
    'type': 'front',
}

export const sideCameraDetails = {
    'title': 'Position your face and neck within the frame.',
    'subTitle': '(from either the left or right side)',
    'type': 'side',
}
