import ProgressBar from "../components/ProgressBar";
import RadioButton from "../components/RadioButton";

function Question({ details }) {
    return (
        <>
            <ProgressBar showProgressBar={true} percentage={details.percentage}></ProgressBar>
            <div className='font-mono text-2xl font-normal leading-8 text-center text-white w-full'>
                <h1 className="font-roboto text-[29px] font-bold leading-[36.25px] text-left mb-7">{details.title}</h1>

                <h6 className="font-roboto text-[18px] font-normal leading-[22.5px] text-left">{details.details}</h6>
            </div>
            <RadioButton details={details} ></RadioButton>
        </>
    )
}
export default Question;


