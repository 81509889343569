function Feature() {
    return (
        <>
            <div className='ease-linear delay-1000 font-mono text-2xl font-normal leading-8 text-center text-white'>
                <p className="delay-1000">
                    Utilizing cutting-edge technology, <b>ApneaLens</b> delivers precise risk assessments.
                </p>
                <br />
                <p>
                    It is continuously enhanced with the latest sleep study findings, ensuring our analysis remains both AI-driven and research-based.
                </p>
            </div>
        </>
    )
}

export default Feature;


