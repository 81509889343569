import scanComplete from './../../scan-complete.svg';
function Scan({ apiResponse }) {
    return (
        <>

            <div className='flex flex-col justify-evenly items-center gap-16 mt-[41%]'>
                <img src={scanComplete} alt="scancomplete" />

                <p className='font-inter text-2xl font-bold leading-[32.5px] text-white'>
                    {apiResponse ? apiResponse : 'Looking good.'}
                </p>
            </div>

        </>
    )
}
export default Scan;


