import { useRef, useState, useEffect } from 'react';
import Human from '@vladmandic/human';
import { useFormContext } from "react-hook-form";


import Button from './../components/Button';



function Camera({ details, setForceShowBtn, setShowLoader, setloaderText, nextStep }) {
    const { register, setValue, setError, clearErrors, formState: { errors } } = useFormContext();
    const width = 320;
    const height = 320;
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [human, setHuman] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [webCamCurrentStatus, setWebCamCurrentStatus] = useState(null);
    const [faceType, setFaceType] = useState('');



    // const [showCapture, setShowCapture] = useState(true);

    const myConfig = {
        debug: true,
        backend: 'webgl',
        useTFWasmBackend: true,
        modelBasePath: './models',
        segmentation: { enabled: false },
        filter: { enabled: true, equalization: false, flip: false },
        face: {
            enabled: true,
            detector: { rotation: true },
            mesh: { enabled: true },
            attention: { enabled: false },
            iris: { enabled: false },
            description: { enabled: false },
            emotion: { enabled: false },
            antispoof: { enabled: false },
            liveness: { enabled: false }
        },
        body: { enabled: false },
        hand: { enabled: false },
        object: { enabled: false },
        gesture: { enabled: true }
    };

    useEffect(() => {
        const loadHuman = async () => {
            setShowLoader(true);
            setloaderText('Loading Camera...');
            const humanInstance = new Human(myConfig);
            await humanInstance.load();
            await humanInstance.warmup();
            await webCam(humanInstance);
            setHuman(humanInstance);

            // Start the webcam stream
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                    videoRef.current.srcObject = stream;

                    // Wait for the video element to load metadata before playing
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play().catch((error) => {
                            console.error('Error attempting to play video:', error);
                        });
                    };

                    setShowLoader(false);
                    setloaderText('');
                } catch (err) {
                    displayError('Error accessing camera');
                }
            }
        };

        loadHuman(); // Call the function

        // Cleanup function to stop the video stream when the component unmounts
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
                videoRef.current.srcObject = null;
            }
        };
    }, []);



    async function webCam(humanInstance) {
        try {
            const devices = await humanInstance.webcam.enumerate();
            const id = devices[0]?.deviceId;
            const webcamStatus = await humanInstance.webcam.start({
                element: document.getElementById('video'),
                crop: false,
                width,
                height,
                id: id || 'default',
            });

            setWebCamCurrentStatus(webcamStatus);
        } catch (err) {
            console.error('Error starting webcam: ', err);
        }
    }

    const captureImage = async () => {
        // setForceShowBtn(true);
        // setShowCapture(false);
        setShowLoader(true);
        setloaderText('Capturing...');
        clearErrors(details.type);
        if (!human || !videoRef.current) {
            return displayError('Error accessing camera');
        };

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const result = await human.detect(canvas);
        let correctImage = false;

        if (result.face.length > 0) {
            const face = result.face[0];
            const angle = face.rotation.angle;
            const yaw = angle.yaw ?? '';
            if (Math.abs(yaw) < 0.3 && Math.abs(yaw) > 0) {
                if (details.type == 'side') {
                    setError(details.type, { type: "custom", message: "Side face is not detected!" });
                } else {
                    correctImage = true;
                }
                setFaceType('Front Face Detected');
            } else {
                if (details.type == 'front') {
                    setError(details.type, { type: "custom", message: "Front face is not detected!" });
                } else {
                    correctImage = true;
                }
                setFaceType('Side Face Detected');
            }
            const capturedData = canvas.toDataURL('image/png');
            setCapturedImage(capturedData);
            const imageBlob = dataURLToBlob(capturedData);

            if (correctImage) {
                // setForceShowBtn(true);
                // setShowCapture(false);
                setValue(details.type, imageBlob);
                nextStep();

            } else {
                setShowLoader(false);
                setloaderText('');
            }
        } else {
            setFaceType('No Face Detected');
            return displayError('No Face Detected');
        }
    };

    function displayError(message) {
        setShowLoader(false);
        setloaderText('');
        setError(details.type, { type: "custom", message: message });
        return;
    }

    function dataURLToBlob(dataURL) {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: mimeString });
    }
    return (
        <>


            <div className=' w-full'>
                <div className='mb-10 bg-[#D9D9D9] text-black mx-[-24px]'>
                    <div className='text-center flex justify-center relative pt-[70%] overflow-hidden'><video ref={videoRef} className='w-full absolute top-0 left-0 h-full object-cover' autoPlay playsInline id="video" crossOrigin="anonymous" muted></video>
                    </div>

                    {/* <div className='flex justify-center mt-2'>
                            <img className='rounded-[50%] cursor-pointer' onClick={captureImage} src={captureImg} alt="capture" width="50" height="50" />
                        </div> */}

                    <canvas ref={canvasRef} width="160" height="160" style={{ display: 'none' }}></canvas>
                    {/* <p>{webCamCurrentStatus}</p> */}
                </div>

                {/* {capturedImage && (
                        <div className='mb-5'>
                            <p>{faceType}</p>
                            <div className='flex justify-center'>
                                <img className='w-full h-52 object-cover'  src={capturedImage} alt="Captured Face" />
                            </div>
                        </div>
                    )} */}

                <input type="hidden" hidden {
                    ...register(details.type, {
                        required: 'Please Capture Image',
                    })}>
                </input>
                {
                    errors[details.type]?.message && (
                        <p role="alert" className="text-red-500 text-center text-[18px] mt-[-15px] mb-[10px] font-roboto">
                            {errors[details.type]?.message}
                        </p>
                    )
                }





                <h1 className="font-inter text-[26px] font-bold leading-[32.5px] text-center text-white">
                    {details.title}
                </h1>
                <h6 className="font-inter text-[20px] font-bold leading-[25px] text-center text-white">{details.subTitle}</h6>

                <Button btnText='Capture' showButton={true} onClick={captureImage} buttonType='button' />
            </div>

        </>
    )
}
export default Camera;


