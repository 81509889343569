import logo from './../../logo.svg';

function Logo({ showLogo }) {
    if (showLogo) {
        return (
            <a href='#' className='inline-block'>
                <img src={logo} className="App-logo" alt="logo" />
            </a>)
    }
    return null;
}

export default Logo;