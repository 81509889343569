function Landing() {
    return (
        <>
            <div className='font-mono text-2xl font-normal leading-8 text-center text-white'>
                <p>
                    Discover your risk of sleep apnea by simply scanning your face with your smartphone.
                </p>
                <br />
                <p>
                    <b>ApneaLens</b> harnesses advanced AI to analyze facial and neck features, providing instant predictions of sleep apnea risk and severity.
                </p>
            </div>
        </>
    )
}
export default Landing;


