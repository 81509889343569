import { useState, useRef, useEffect } from 'react';
import { DevTool } from "@hookform/devtools";

import { steps } from './steps/data/data';

import Logo from './components/Logo';
import Button from './components/Button';
import Header from './components/Header'
import Loader from './components/Loader'

import { useForm, FormProvider } from "react-hook-form"

import { CSSTransition } from 'react-transition-group';
import axios from 'axios';

function FacialMonographyForm() {

    const [inProp, setInProp] = useState(true);
    const nodeRef = useRef(null);
    const methods = useForm();

    const apiUrl = process.env.REACT_APP_API_URL;

    const [activeStep, setActiveStep] = useState(0);

    const [forceShowBtn, setForceShowBtn] = useState(false);

    const [showLoader, setShowLoader] = useState(false);
    const [loaderText, setloaderText] = useState('');

    const [apiResponse, setApiResponse] = useState(null);

    const renderStep = (stepIndex) => {
        // setInProp(false);
        if (stepIndex >= 0 && stepIndex < steps.length) {
            // setInProp(true);
            return steps[stepIndex].component({ setForceShowBtn, setShowLoader, setloaderText, apiResponse, nextStep });
        }
        return null;
    };

    const handelNextStep = () => {

        methods.clearErrors();
        setInProp(false); // Trigger exit transition
        setForceShowBtn(false);
        setTimeout(() => {
            setActiveStep((prevStep) => {
                let prevSleep = methods.getValues('prev_sleep_study');

                let nextStep = prevStep + 1;
                if (prevSleep == 'No' && steps[nextStep].name == 'QuestionTwo') {
                    nextStep++;

                }
                return nextStep >= steps.length ? prevStep : nextStep;
            });
            setInProp(true);
            setShowLoader(false);
            setloaderText('');
        }, 300);

    }
    const nextStep = async () => {
        if (steps[activeStep]?.buttonType == 'submit') {
            methods.handleSubmit(onSubmit);
            return;
        }
        const isValid = await methods.trigger();
        if (isValid) {
            handelNextStep();
        }
    };

    const handleCancelClick = () => {
        setInProp(false);
        setTimeout(() => {
            setActiveStep(0);
            methods.reset();
            setInProp(true);
        }, 300);
    }
    const handleBackClick = () => {
        setInProp(false);
        setTimeout(() => {
            setActiveStep((prevStep) => {
                let prevSleep = methods.getValues('prev_sleep_study');
                let step = Math.max(prevStep - 1, 0)
                if (prevSleep == 'No' && steps[step].name == 'QuestionTwo') {
                    step--;
                }
                return step;
            });
            methods.clearErrors();
            setInProp(true);
        }, 300);
    };

    const onSubmit = (data) => {
        setShowLoader(true);
        setloaderText('Diagnosing...');
        axios.post(`${apiUrl}/api/facial-morphology-details`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                setShowLoader(false);
                setloaderText('');
                setApiResponse(response.data?.data ?? 'Success');
                handelNextStep();
            })
            .catch(error => {
                setShowLoader(false);
                setloaderText('');
                console.error(error);
            });
    };



    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className='bg-[#373737] max-w-[767px] mx-auto'
                >
                    <CSSTransition nodeRef={nodeRef} in={inProp} timeout={300} classNames="my-node">
                        <div ref={nodeRef} className={`flex justify-center items-center w-full`}>
                            <Loader showLoader={showLoader} customText={loaderText} />
                            <div className={`bg-[#373737] max-w-[767px] w-full pt-10 pb-24 px-6 flex flex-col justify-between items-center h-screen overflow-y-auto  relative ${showLoader ? 'hidden' : 'block'}`}>
                                <div className='flex flex-col justify-start items-center w-full gap-10'>
                                    <Logo showLogo={steps[activeStep]?.showLogo} />
                                    <Header
                                        showHeader={steps[activeStep]?.showHeader}
                                        backClick={handleBackClick}
                                        cancelClick={handleCancelClick}
                                    />
                                    {renderStep(activeStep)}
                                </div>
                                <Button btnText={steps[activeStep]?.buttonText} showButton={steps[activeStep]?.showButton || forceShowBtn} onClick={nextStep} buttonType={steps[activeStep]?.buttonType} isLink={steps[activeStep]?.isLink} linkUrl={steps[activeStep]?.linkUrl} disabled={!inProp || showLoader} />

                            </div>
                        </div>
                    </CSSTransition>

                </form >
            </FormProvider >

            <DevTool control={methods.control} /> {/* set up the dev tool */}
        </>
    )
}

export default FacialMonographyForm;