function Header({ showHeader, backClick, cancelClick }) {
    if (showHeader) {
        return (
            <div className='w-full flex justify-between items-center mb-2'>
                <button type="button" onClick={backClick} className="font-roboto text-base font-normal leading-5 text-center text-[#1B7CED]">Back</button>
                <button type="button" onClick={cancelClick} className="font-roboto text-base font-normal leading-5 text-center text-[#1B7CED]">Cancel</button>
            </div>
        )
    }
    return null;
}

export default Header;