import './App.css';


import FacialMonographyForm from './facial-monography/FacialMonographyForm'

function App() {
  return (
    <FacialMonographyForm></FacialMonographyForm>
  );
}

export default App;
