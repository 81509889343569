import { useFormContext } from "react-hook-form";

function RadioButton({ details }) {
    const { register, watch, setValue, formState: { errors } } = useFormContext(); // Access form methods via context

    // Watch the current value of the radio button group
    const currentValue = watch(details.name);

    return (
        <div className="flex flex-wrap gap-[10px] w-full">
            {details.options.map((option, index) => (
                <label
                    key={index}
                    htmlFor={option.value}
                    className={`cursor-pointer px-5 py-5 rounded-lg border border-gray-400 w-full text-white font-roboto text-[18px] font-normal leading-[22.5px] text-left ${currentValue === option.value ? 'bg-[#1B7CED]' : 'bg-[#6F6F6F]'} 
                    ${errors[details.name] ? 'border-red-500' : ''}`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value={option.value}
                        {...register(details.name, {
                            required: "Please select an option",
                            onChange: (e) => {
                                setValue(details.name, e.target.value);
                            }
                        })}
                        id={option.value}
                        checked={currentValue === option.value}
                    />
                    <span>{option.displayText}</span>
                </label>
            ))}
            {errors[details.name]?.message && (
                <p role="alert" className="text-red-500 mt-2 text-[18px] font-roboto">
                    {errors[details.name]?.message}
                </p>
            )}
        </div>
    );
}

export default RadioButton;
