function Button({ showButton, btnText, onClick, buttonType, isLink, linkUrl, disabled }) {
    // const handleClick = (e) => {
    //     if (buttonType !== 'submit') {
    //         e.preventDefault();
    //         console.log('called submit');
    //     }
    //     onClick();
    // };
    const classes = 'bg-[#1B7CED] font-inter text-base font-bold leading-6 text-center text-white rounded-[12px] px-10 py-3 w-full block'
    if (showButton) {
        return (
            <div className='fixed bottom-5 w-full  px-5 left-0 max-w-[756px] ml-[50%] translate-x-[-50%]'>
                {isLink ? (
                    <a href={linkUrl} className={classes}>
                        {btnText}
                    </a>
                ) : (
                    <button disabled={disabled} type={buttonType} className={classes} onClick={onClick}>
                        {btnText}
                    </button>

                )}
            </div>
        )
    }
    return null;
}

export default Button;