import { useFormContext } from "react-hook-form";

function Input({ name, type, placeholder, label }) {
    const { register, watch, setValue, formState: { errors } } = useFormContext(); // Access form methods via context

    // Watch the current value of the radio button group
    const currentValue = watch(name);

    return (
        <div className="flex flex-wrap gap-2 w-full">
            <label
                htmlFor={name}
                className={`w-full`}
            >
                <div className="font-kumbh-sans text-[15px] font-normal leading-[18.75px] text-left text-white ml-3 mb-1">
                    {label}
                </div>
                <input type={type} placeholder={placeholder}
                    className={`text-white border border-[#E2E2E2] bg-[#00000040] rounded-[10px] w-full p-2 ${errors[name] ? 'border-[#EF4444]' : ''}`}
                    {...register(name, {
                        required: 'Please Enter Email',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Please enter an valid email'
                        },
                        onChange: (e) => {
                            setValue(name, e.target.value); // Ensure value is set in form state
                        }
                    })}></input>
            </label>
            {
                errors[name]?.message && (
                    <p role="alert" className="text-red-500 mt-2 text-[18px] font-roboto">
                        {errors[name]?.message}
                    </p>
                )
            }
        </div >
    );
}

export default Input;
