import ProgressBar from "../components/ProgressBar";
import smile from './../../smile.svg';
import top from './../../top.svg';
import bottom from './../../bottom.svg';
import left from './../../left.svg';
import right from './../../right.svg';

function ScanInstruction({ details }) {
    return (
        <>
            <ProgressBar showProgressBar={true} percentage={details.percentage}></ProgressBar>
            <div className='font-mono text-2xl font-normal leading-8 text-center text-white w-full flex flex-col  gap-10'>
                <div className="flex justify-center relative">
                    <div className="circle">
                        <div className="circle-verticle">
                            <img
                                src={top}
                                alt="top"
                                className=""
                            /><img
                                src={bottom}
                                alt="bottom"
                                className=""
                            />
                        </div>
                        <div className="circle-horizontal">
                            <img
                                src={left}
                                alt="left"
                                className=""
                            /><img
                                src={right}
                                alt="right"
                                className=""
                            /></div>
                    </div>
                    <img
                        src={smile}
                        alt="Smile"
                        className="smiley-circle"
                    />
                </div>

                <h1 className="font-roboto text-[24px] font-medium leading-7 text-center">{details.title}</h1>
                <ul className="font-inter text-[17px] font-normal leading-[21.25px] text-left list-disc list-inside pl-4">
                    {details.list.map((instruction, index) => (
                        <li className="list-outside mb-5" key={index}>{instruction}</li>
                    ))}
                </ul>

            </div>
        </>
    )
}
export default ScanInstruction;


