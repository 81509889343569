import Input from "../components/Input";

function Email({ }) {
    return (
        <>
            <div className='mt-5 mb-10 font-mono text-2xl font-normal leading-8 text-center text-white w-full'>
                <h1 className="font-inter text-[26px] font-medium leading-[32.5px] text-center mb-5">Get Your Results Instantly</h1>

                <h6 className="font-roboto text-[18px] font-normal leading-[22.5px] text-center">Enter your email address below to receive your analysis immediately.</h6>
            </div>
            <Input name={'email'} type={'text'} placeholder={''} label={'Email Address'}></Input>

            <div className='font-mono text-base font-normal leading-5 text-center text-white mt-auto'>
                <p>Read our <a className="underline" href="https://royahealth.app/terms-of-service/">Terms</a> and <a className="underline" href="https://royahealth.app//privacy-policy/">Privacy Policy</a> here.</p>
            </div>
        </>
    )
}
export default Email;


