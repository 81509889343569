function Loader({ showLoader, customText }) {
    return (
        <>
            <div className={`bg-[#373737] max-w-[767px] w-full min-h-screen h-screen loader-main ${showLoader ? 'flex' : 'hidden'}`}>
                <div id="loader"></div>
                <p className="font-roboto text-[24px] font-normal leading-[22.5px] text-center text-white mt-5">{customText}</p>
            </div>
        </>
    )
}

export default Loader;


