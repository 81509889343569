
function Agreement() {
    return (
        <>
            <div className='font-mono text-2xl font-normal leading-8 text-center text-white'>
                <p>
                    <b>ApneaLens</b> is not FDA approved and does not provide medical diagnoses. It should not replace consultations with a doctor or conventional sleep tests.
                </p>
            </div>

            <div className='font-mono text-base font-normal leading-5 text-center text-white'>
                <p>Read our <a className="underline" href="https://royahealth.app/terms-of-service/">Terms</a> and <a className="underline" href="https://royahealth.app//privacy-policy/">Privacy Policy</a> here.</p>
            </div>
        </>
    )
}

export default Agreement;